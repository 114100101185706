<p-toolbar styleClass="header-toolbar">
  <div class="p-toolbar-group-left">
    <span>
      <img src="assets/logo/hellebrekers_logo.png">
      <h1>BetonTrace</h1>
    </span>
  </div>

  <div class="p-toolbar-group-right version">
    v. {{version}}
  </div>
</p-toolbar>
