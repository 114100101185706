import { Component } from '@angular/core';
import { version } from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private static version: string = version;
  private static waitMessage: string = 'Een ogenblik geduld wij zijn uw order aan het zoeken...';
  title = 'BetonTracePWA';

  static updateWaitMessage(waitMessage: string): void {
    this.waitMessage = waitMessage;
  }

  static getWaitMessage(): string {
    return this.waitMessage;
  }

  static resetWaitMessage(): void {
    this.updateWaitMessage('Een ogenblik geduld wij zijn uw order aan het zoeken...');
  }

  static getVersion(): string {
    return this.version;
  }

  getWaitMessage(): string {
    return AppComponent.waitMessage;
  }

  constructor() {
  }

}
