import {Component, OnInit, Output, EventEmitter, NgZone} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {Order} from '../../model/order';
import {PrimeIcons} from 'primeng/api';
import {Bon} from '../../model/bon';
import {Rit} from '../../model/rit';
import {Request} from '../../model/request';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import {TraceError} from '../../model/trace-error';
import {ActivatedRoute, Router} from '@angular/router';
import Map from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Style, Text, Fill, Stroke} from 'ol/style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import OSM from 'ol/source/OSM';
import * as olProj from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import {AppComponent} from '../../app.component';
import {Error} from '../../model/error';

@Component({
  selector: 'app-trace',
  templateUrl: './trace.component.html',
  styleUrls: ['./trace.component.css']
})
export class TraceComponent implements OnInit {

  map: Map;
  latitudeTruck = 0;
  longtitudeTruck = 0;
  order: Order;
  bon: Bon[];
  rit: Rit[];
  events: any[];
  error: any;
  @Output() mapReady = new EventEmitter<Map>();

  truckPointerStyle = {
    truck: new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: 'assets/markers/marker-icon.png'
      }),
      text: new Text({
        text: 'Bon',
        scale: 1.2,
        fill: new Fill({
          color: '#fff'
        }),
        stroke: new Stroke({
          color: '0',
          width: 3
        })
      })
    })
  };

  icon: any;

  paramTraceRequest: Request;

  paramKlantnaam: string;
  paramTraceCode: string;
  paramTracePin: string;
  klantLogo: string;

  traceForm = this.formBuilder.group({
    traceCode: '',
    tracePin: '',
    klantnaam: ''
  });

  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone
  ) {
    this.order = null;
  }

  ngOnInit(): void {
    this.error = null;
    this.route.queryParamMap.subscribe(query => {
      // this.paramKlantnaam = window.location.hostname.replace('.trace.tclick.nl', '');
      // KiK Omgeschreven, zodat waarde voor de punt gepakt wordt, gaan er hier wel vanuit dat domein met trace begint. (trace en tracetest)
      this.paramKlantnaam = window.location.hostname.substring(0, window.location.hostname.indexOf('.trace'));
      this.paramTraceCode = query.get('traceCode');
      this.paramTracePin = query.get('tracePin');
      if (this.paramKlantnaam === 'localhost') {
        this.paramKlantnaam = 'albeton';
      }
    });

    if (this.paramKlantnaam != null && this.paramKlantnaam !== '' && !this.paramKlantnaam.startsWith('trace')) {
      this.paramTraceRequest = new Request();
      this.paramTraceRequest.klantnaam = this.paramKlantnaam;
      this.klantLogo = 'assets/klantlogo/' + this.paramKlantnaam + 'png';

      if (this.paramTraceCode != null || this.paramTraceCode !== '') {
        this.paramTraceRequest.traceCode = this.paramTraceCode;
      }

      if (this.paramTracePin !== null || this.paramTracePin !== '') {
        this.paramTraceRequest.tracePin = this.paramTracePin;
      }

      if (this.paramTraceRequest != null) {
        this.traceForm = this.formBuilder.group(this.paramTraceRequest);
      }
    } else {
      // Navigate to error page!!!
      this.error = new Error(1000, 'Geen betoncentrale ingevuld, controleer de link!');
    }

    this.events = [
      {status: 'Order geplaatst', date: '15/10/2020 10:30', icon: PrimeIcons.CHECK , color: '#32AB5C'},
      {status: 'In uitvoering', date: '15/10/2020 14:00', icon: PrimeIcons.RADIO_OFF, color: '#1C3B69'},
      {status: 'Afgeleverd', date: '15/10/2020 16:15', icon: PrimeIcons.RADIO_OFF, color: '#1C3B69'},
    ];
    // this.createMap();
  }

  klantError(): boolean {
    return (this.error instanceof Error) ? true : false;
  }

  onSubmit(traceForm: Request): void {
    this.spinner.show();
    let needTimeout = true;
    setTimeout(() => {
      AppComponent.updateWaitMessage('Het zoeken van uw order duurt wat langer dan normaal, mogelijk is de verbinding langzaam...');
    }, 5000);
    setTimeout(() => {
      AppComponent.updateWaitMessage('Het zoeken van uw order duurt wel heel erg lang, wij doen nog een laatste poging...');
    }, 10000);
    setTimeout(() => {
      if (needTimeout) {
        // hide spinner after timeout
        this.spinner.hide();

        // Create Error message
        this.error = new TraceError();
        this.error.responseCode = '9001';
        this.error.responseMessage = 'Het lijkt er op dat de verbinding langzaam of verbroken is. Probeer het later opnieuw of neem contact op met uw betoncentrale.';
      }
    }, 15000);

    if (traceForm.traceCode !== '' && traceForm.traceCode != null && traceForm.tracePin !== '' &&
      traceForm.tracePin != null && traceForm.klantnaam !== '' && traceForm.klantnaam != null) {
      this.apiService.getOrder(traceForm).subscribe((req: Order) => {
        if (document.getElementById('truck_map') != null) {
          document.getElementById('truck_map').innerHTML = '';
        }

        if (req !== null) {
          if (req.responseCode === '0000') {
            AppComponent.updateWaitMessage('Gevonden!');
            this.error = null;

            this.order = req;
            this.order.ritList = JSON.parse(this.order.ritten);
            this.order.bonList = JSON.parse(this.order.bonnen);

            if (this.order.m3Open == 0 && (this.order.m3Geleverd > 0 || this.order.m3Geleverd == this.order.m3Besteld)) {
              this.chanceEventStatus(1, PrimeIcons.CHECK, '#32AB5C');
              this.chanceEventStatus(2, PrimeIcons.CHECK, '#32AB5C');
            } else if (this.order.m3Open > 0 && this.order.m3Geleverd > 0) {
              this.chanceEventStatus(1, PrimeIcons.CHECK, '#32AB5C');
              this.chanceEventStatus(2, PrimeIcons.RADIO_OFF, '#1C3B69');
            } else {
              this.chanceEventStatus(1, PrimeIcons.RADIO_OFF, '#1C3B69');
              this.chanceEventStatus(2, PrimeIcons.RADIO_OFF, '#1C3B69');
            }

            // Rerender the map so it will be visible after hide
            this.createMap();
            this.addBonTruck(this.order.bonList);
            const locationCounter = this.getLatLongCenter(this.order.bonList);
            if (locationCounter === 0) {
              // Also only shown when there are locations
              document.getElementById('truck_map').innerHTML = '';
            }
          } else {
            this.order = null;
          }

          if (req.responseCode !== '0000' && req.responseCode !== '') {
            this.order = null;
            this.error = new TraceError();
            this.error.responseCode = req.responseCode;
            this.error.responseMessage = req.responseMessage;
          }
        } else {
          this.order = null;
          this.error = new TraceError();
          this.error.responseCode = '2000';
          this.error.responseMessage = 'Geen order gevonden!';
        }
        needTimeout = false;
        this.spinner.hide();
      });
    } else {
      this.order = null;
      this.error = new TraceError();
      this.error.responseCode = '1000';
      this.error.responseMessage = 'U moet een traceCode & TracePin invoeren!';
      this.spinner.hide();
    }

    // Reset wait message to default!
    AppComponent.resetWaitMessage();
  }

  chanceEventStatus(index: number, status: string, color: string) {
    this.events[index].icon = status;
    this.events[index].color = color;
  }

  createMap(): void {
    // Create the Map.
    this.map = new Map({
      target: 'truck_map',
      layers: [
        new TileLayer({
          source: new OSM()
        })
      ],
      view: new View({
        center: olProj.fromLonLat([6.0000, 52.0000]),
        zoom: 6
      })
    });
  }

  addBonTruck(bonnenList): void {
    const featureList = [];
    bonnenList.forEach((bon: Bon) => {
      if (bon.status > 0 && bon.status < 4) {
        const tmpFeature = new Feature({
          type: 'truck',
          name: 'Bonnr: ' + bon.bonnr,
          geometry: new Point(olProj.fromLonLat([bon.longtitude, bon.latitude]))
        });
        featureList.push(tmpFeature);
      }
    });

    const layer = new VectorLayer({
      source: new VectorSource({
        features: featureList
      }),
      style: (feature) => {
        const f = this.truckPointerStyle[feature.get('type')];
        f.getText().setText(feature.get('name'));
        return f;
      }
    });

    this.map.addLayer(layer);
  }

  minuten2Tijd(minutenString: number): string {
    let tijd = '00:00';
    if (minutenString > 0) {
      if (minutenString < 1440) {
        const uren = Math.floor(minutenString / 60);
        const minuten = minutenString % 60;
        (uren < 10) ? tijd = '0' + uren + ':' : tijd = '' + uren + ':';
        (minuten < 10) ? tijd = tijd + '0' + minuten : tijd = tijd + minuten;
      } else {
        tijd = '23:59';
      }
    }

    return tijd;
  }

  estimated2Tijd(minutenString: number): string {
    let estimated = '??:??';
    let blokTime = 10;

    // Als minuten String > 0 return een tijdblok
    if (minutenString > 0) {
      if (minutenString < 1440) {
        if (minutenString > blokTime) {
          estimated = this.minuten2Tijd(minutenString-blokTime) + ' - ' + this.minuten2Tijd(minutenString+blokTime);
        } else {
          estimated = '23:50 - 00:10';
        }
      } else {
        estimated = '23:50 - 00:10';
      }
    }

    return estimated;
  }

  getLatLongCenter(bonnenList: Bon[]): number {
    let tmpLat = 0.0;
    let tmpLong = 0.0;
    let tmpCounter = 0;

    bonnenList.forEach((bon: Bon) => {
      if (bon.status < 5) {
        if (bon.longtitude > 0 && bon.latitude > 0) {
          tmpCounter++;
          tmpLat += bon.latitude;
          tmpLong += bon.longtitude;
        }
      }
    });

    if (tmpCounter > 0) {
      tmpLat = tmpLat / tmpCounter;
      tmpLong = tmpLong / tmpCounter;
    }

    this.map.getView().setZoom(13);
    this.map.getView().setCenter(olProj.fromLonLat([tmpLong, tmpLat]));

    return tmpCounter;
  }
}
