import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  version: any;

  constructor() { }

  ngOnInit(): void {
    this.version = AppComponent.getVersion();
  }

}
