import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Order} from '../model/order';
import {Request} from '../model/request';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly APIURL = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  getOrder(request: Request): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('', '');

    return this.http.post<Order>(this.APIURL + '/trace/' , request);
  }
}
