<div class="overflow-auto" *ngIf="!klantError()">
  <div class="grid col-12">
    <div class="col-12 md:col-5">

    </div>
    <div class="col-12 md:col-2">
      <img src="assets/klantlogo/{{paramKlantnaam}}.png"/>
    </div>
    <div class="col-12 md:col-5">

    </div>
  </div>
  <div class="grid col-12">
    <div class="col-12 md:col-1">

    </div>
    <div class="col-12 md:col-10">
      <p-fieldset legend="Zoek beton order">
        <form [formGroup]="traceForm" (ngSubmit)="onSubmit(traceForm.value)">
          <div class="grid">
            <div class="p-field col-12 md:col-4">
              <label for="traceCode">Trace code:</label>
              <input type="text" id="traceCode" placeholder="Trace code" pInputText formControlName="traceCode">
            </div>
            <div class="p-field col-12 md:col-4">
              <label for="tracePin">Trace pincode:</label>
              <input type="text" id="tracePin" placeholder="Trace pincode" pInputText formControlName="tracePin">
            </div>
            <input type="hidden" id="klantnaam" formControlName="klantnaam">
            <div class="field col-12 md:col-4">
              <button type="submit" pButton pRipple label="Zoek order"></button>
            </div>
          </div>
        </form>
      </p-fieldset>
    </div>
    <div class="col-12 md:col-1">
      &nbsp;
    </div>
  </div>

  <div class="grid col-12" *ngIf="error != null">
    <div class="col-12 md:col-1">
      &nbsp;
    </div>
    <div class="col-12 md:col-10">
      <p-fieldset legend="Er is iets fout gegaan!">
        <h2>Er is iets fout gegaan!</h2>
        <p>foutcode: {{error.responseCode}}<br />
          foutmelding: {{error.responseMessage}}
        </p>
      </p-fieldset>
    </div>
    <div class="col-12 md:col-1">
      &nbsp;
    </div>
  </div>

  <div class="grid col-12" *ngIf="order != null">
    <div class="col-12 md:col-2">
      &nbsp;
    </div>
    <div class="col-12 md:col-4 hKlant">
      <strong>{{order.klantNaam}}</strong><br/>
<!--      {{order.klantContact}}<br/>-->
      {{order.werkAdres}}<br/>
      {{order.werkPostcode}} {{order.werkPlaats}}<br/><br/>
      <strong>Receptnaam:</strong> {{order.receptnaam}}<br/><br/>
      <strong>Correctie recepten:</strong><br/>
      {{order.correceptnamen}}
    </div>
    <div class="col-12 md:col-2 hBesteld">
      <strong>Datum:<br /></strong>
      {{order.orderdatum}}<br />
    </div>
    <div class="col-12 md:col-2 hBesteld">
      &nbsp;<strong>Besteld:<br /></strong>
      {{order.m3Besteld | number : '.2'}} m&#179;<br />
      <strong>Geleverd:<br /></strong>
      {{order.m3Geleverd | number : '.2'}} m&#179;<br />
      <strong>Open:<br /></strong>
      {{order.m3Open | number : '.2'}} m&#179;<br />
    </div>
    <div class="col-12 md:col-2">
      &nbsp;
    </div>

    <div class="col-12 md:col-1">
      &nbsp;
    </div>
    <div class="col-12 md:col-10">
      <p-timeline [value]="events" layout="horizontal">
        <ng-template pTemplate="content" let-event>
          {{event.status}}
        </ng-template>
        <ng-template pTemplate="marker" let-event>
          <span class="custom-marker p-shadow-2" [style.backgroundColor]="event.color">
              <i [ngClass]="event.icon"></i>
          </span>
        </ng-template>
      </p-timeline>
    </div>
    <div class="col-12 md:col-1">
      &nbsp;
    </div>
    <div class="grid col-12" *ngIf="order.ritList != null && order.ritList.length > 0">
      <div class="col-12 md:col-12" style="text-align: center;">
        &nbsp;<h2>Geplande Ritten:</h2>
      </div>
      <div class="col-12 md:col-1">
        &nbsp;
      </div>
      <div class="col-12 md:col-10">
        <p-table dataKey="ritnr" [value]="order.ritList">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 3rem;"></th>
              <th style="width: 10rem">Rit#: </th>
              <th class="hRightAlign" style="width: 5rem;">Geplande lading:</th>
              <th class="hRightAlign" style="width: 5rem">Wens aankomst</th>
              <th class="hRightAlign" style="width: 5rem">Geplande aankomst:</th>
              <th class="hRightAlign" style="width: 5rem"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rit let-expanded="expanded">
            <tr>
              <td class="hRightAlign" >
                <button type="button" pButton pRipple [pRowToggler]="rit" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
              </td>
              <td>Rit: {{rit.ritnr}}</td>
              <td class="hRightAlign">{{rit.m3Weg | number : '.2'}} m&#179;</td>
              <td class="hRightAlign">{{minuten2Tijd(rit.wensAankomstWerk)}} uur</td>
              <td class="hRightAlign">{{estimated2Tijd(rit.aankomstWerk)}} uur</td>
              <td class="hRightAlign"><!-- <i class="{{bon.checkIcon}}"> </i>--></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-rit>
            <tr>
              <td colspan="6">
                <table>
<!--                  <tr>-->
<!--                    <td colspan="6">Recept: {{rit.receptnaam}}</td>-->
<!--                  </tr>-->
                  <tr>
                    <td></td>
                    <td>Start laden:</td>
                    <td>Aankomst werk:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>{{minuten2Tijd(rit.startLaden)}} uur</td>
                    <td>{{minuten2Tijd(rit.aankomstWerk)}} uur</td>
                    <td></td>
                  </tr>
                </table>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="col-12 md:col-1">
        &nbsp;
      </div>
    </div>

    <div class="grid col-12" *ngIf="order.bonList != null && order.bonList.length > 0">
      <div class="col-12 md:col-12" style="text-align: center;">
        &nbsp;<h2>Bonnen in uitvoer/voltooid:</h2>
      </div>

      <div class="col-12 md:col-1">
        &nbsp;
      </div>
      <div class="col-12 md:col-10">
        <p-table dataKey="bonnr" [value]="order.bonList">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 3rem;"></th>
              <th style="width: 10rem">Order#: </th>
              <th class="hRightAlign" style="width: 5rem;">Lading:</th>
              <th class="hRightAlign" style="width: 5rem"></th>
              <th class="hRightAlign" style="width: 5rem">verwachte aankomst:</th>
              <th class="hRightAlign" style="width: 5rem"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-bon let-expanded="expanded">
            <tr>
              <td class="hRightAlign">
                <button type="button" pButton pRipple [pRowToggler]="bon" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
              </td>
              <td>Bonnr: {{bon.bonnr}}</td>
              <td class="hRightAlign">{{bon.m3Weg | number : '.2'}} m&#179;</td>
              <td class="hRightAlign"> &nbsp;</td>
              <td class="hRightAlign">{{bon.aankomstWerk | date: 'HH:mm'}} uur</td>
              <td class="hRightAlign">
                <span *ngIf="bon.status > 0 && bon.status < 4"><i class="pi pi-info-circle" [pRowToggler]="bon"></i></span>
                <span *ngIf="bon.status >= 4" ><i class="pi pi-check-circle"></i></span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-bon>
<!--            <tr>-->
<!--              <td colspan="6">Opmerking: {{bon.opmerking}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td colspan="6">lat: {{bon.latitude}} | long: {{bon.longtitude}}</td>-->
<!--            </tr>-->
            <tr>
              <td colspan="6">
                <table>
                  <tr>
                    <td></td>
                    <td>Start laden:</td>
<!--                    <td>Vertrek centrale:</td>-->
                    <td>Aankomst werk:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>{{bon.startLaden | date: 'HH:mm'}}</td>
<!--                    <td>{{bon.vertrekCentrale | date: 'HH:mm'}}</td>-->
                    <td>{{bon.aankomstWerk | date: 'HH:mm'}}</td>
                    <td></td>
                  </tr>
                </table>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="col-12 md:col-1">
        &nbsp;
      </div>
    </div>
  </div>

  <div class="grid col-12">
    <div class="col-12 md:col-4">
      &nbsp;
    </div>
    <div class="col-12 md:col-4">
      <div id="truck_map"></div>
    </div>
    <div class="col-12 md:col-4">
      &nbsp;
    </div>
  </div>
</div>


<div class="overflow-auto" *ngIf="klantError()">
  <div class="grid col-12">
    <div class="col-12 md:col-1">
      &nbsp;
    </div>
    <div class="col-12 md:col-6">
      <h1>Er is iets fout gegaan!</h1>
      <p>Wij kunnen de opgevraagde omgeving niet vinden!</p>
      <p>Als u deze link niet uit de e-mail heeft, controleer hem dan nog eens goed.<br/>
        Mocht u er niet uitkomen neem contact op met uw beton leverancier.</p>
    </div>
    <div class="col-12 md:col-4">
      <img src="assets/logo/hellebrekers_logo.png" width="200px">
    </div>
    <div class="col-12 md:col-1">
      &nbsp;
    </div>
  </div>
</div>
